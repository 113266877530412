

export const scenes = [
	{id:"0001",vidSrc:'initialHome',labels:['label_0_connected_city'],},
	{id:"0002",vidSrc:'ConventionCenter_Building',menus:['menu0_con_center'],transIn:'TO_CONVENTION',transOut:'FROM_CONVENTION',loop:true,},
	{id:"2_1",vidSrc:'multicarrier',menus:['menu1_multicarrier','menu2_multicarrier'],transIn:'TO_multicarrier',transOut:'FROM_multicarrier',},
	{id:"2_1_1",vidSrc:'Spotty_Connectivity',loop:true},
	{id:"2_1_2",vidSrc:'Verizon_Neutral_Host_Inf',menus:["menu5_neutral_network"],loop:true,},
	{id:"2_1_2_1",vidSrc:'Verizon_Neutral_Host',loop:true,},
	{id:"2_1_3",vidSrc:'Private_Network_Inf',menus:['menu4_private_network'],loop:true,},
	{id:"2_1_3_1",vidSrc:'Private_Network',loop:true,},
	{id:"2_1_4",vidSrc:'Radio_Solution',},
	{id:"2_1_5",vidSrc:'dropped_calls',transIn:'TO_dropped_calls',transOut:'FROM_dropped_calls',loop:true,},
	{id:"2_1_6",vidSrc:'data_access',transIn:'TO_data_access',transOut:'FROM_data_access',loop:true,},
	{id:"2_1_7",vidSrc:'latency',transIn:'TO_latency',transOut:'FROM_latency',loop:true,},

	{id:"3_1",vidSrc:'cashierless',menus:['menu2_cashierless','menu2B_cashierless','menu2C_cashierless'],transIn:'TO_cashierless',transOut:'FROM_cashierless',loop:true,},
	{id:"3_1_0",vidSrc:'authentication',transIn:'TO_authentication',transOut:'FROM_authentication',loop:true,},
	
	{id:"3_1_1",vidSrc:'item_tracking',transIn:'TO_item_tracking',transOut:'FROM_item_tracking',loop:true,},
	{id:"3_1_2",vidSrc:'cashierless_security',transIn:'TO_cashierless_security',transOut:'FROM_cashierless_security',loop:true,},
	{id:"3_1_3",vidSrc:'cashierless_out',transIn:'TO_cashierless_out',transOut:'FROM_cashierless_out',loop:true,},
	{id:"3_1_5",vidSrc:'Cashierless_Checkout_Solution',loop:true,},
	{id:"3_1_4",vidSrc:'long_q',transIn:'TO_long_q',transOut:'FROM_long_q',loop:true,},
	
	{id:"4_1",vidSrc:'access_main',menus:['menu3_accelerated_access','menu3B_accelerated_access','menu3C_accelerated_access'],transIn:'TO_access_main',transOut:'FROM_access_main',},
	{id:"4_1_8",vidSrc:'accelerated_access'},
	{id:"4_1_1",vidSrc:'registration',transIn:'TO_registration',transOut:'FROM_registration',loop:true,},
	{id:"4_1_2",vidSrc:'reduced_security',transIn:'TO_reduced_security',transOut:'FROM_reduced_security',loop:true,},
	{id:"4_1_4",vidSrc:'slow_identity',transIn:'TO_slow_identity',transOut:'FROM_slow_identity',loop:true,},
	{id:"4_1_5",vidSrc:'front_entry',transIn:'TO_front_entry',transOut:'FROM_front_entry',loop:true,},
	{id:"4_1_6",vidSrc:'back_entry',transIn:'TO_back_entry',transOut:'FROM_back_entry',loop:true,},
	{id:"4_1_7",vidSrc:'tokens',transIn:'TO_tokens',transOut:'FROM_tokens',loop:true,},
];

export const labels = [
	{id:"label_0_connected_city",text:"Convention Center",x:0.11,y:0.2,target:'0002'},
	{id:"label_1_conn_center",text:"Manufacturing",x:0.5,y:0.5,target:'0003'},
];

export const menuLists = [
	{id:"menu0_con_center",x:0.690,y:0.4,menuItems:[
		{id:"menuItem0_con_center",text:"Wireless Multi-Carrier Connectivity",target:'2_1'},
		{id:"menuItem1_con_center",text:"Cashierless Checkout",target:'3_1'},
		{id:"menuItem2_con_center",text:"Accelerated Access",target:'4_1'},
	],},
	{id:"menu1_multicarrier",x:0.690,y:0.1,heading:"The Problem",menuItems:[
		{id:"menuItem0_multi",text:"Spotty Coverage",target:'2_1_1'},
		{id:"menuItem6_multi",text:"Dropped Calls",target:'2_1_5'},
		{id:"menuItem7_multi",text:"Limited Data Access",target:'2_1_6'},
		{id:"menuItem8_multi",text:"Device Latency",target:'2_1_7'},
		{id:"menuItem5_multi",text:"Reduced Security",target:'4_1_2'},
			],},
	{id:"menu2_multicarrier",x:0.690,y:0.6,heading:"The Solution",menuItems:[
		{id:"menuItem21_multi",text:"Neutral Host Network",target:'2_1_2'},
		{id:"menuItem22_multi",text:"Private Wireless Solution",target:'2_1_3'},
		
	],},
	{id:"menu2_cashierless",x:0.05,y:0.1,heading:"The Problem",menuItems:[
		{id:"menuItem2A_access",text:"Traditional Checkout",target:'3_1_4'},
		
	],},
	{id:"menu2B_cashierless",x:0.050,y:0.25,heading:"Solution",menuItems:[
		{id:"menuItem2B_access",text:"Cashierless Checkout",target:'3_1_5'},
		
		
	],},
	{id:"menu2C_cashierless",x:0.050,y:0.4,heading:"How It Works",menuItems:[
		{id:"menuItem2_access",text:"Authentication and Entry",target:'3_1_0'},
		{id:"menuItem0_cashierless",text:"Item Tracking and Digital Cart",target:'3_1_1'},
		{id:"menuItem2_cashierless",text:"Automatic Cashierless Checkout",target:'3_1_3'},
		{id:"menuItem1_cashierless",text:"Secure Connections For System Devices",target:'3_1_2'},
		
	],},
	{id:"menu3_accelerated_access",x:0.05,y:0.1,heading:"The Problem",menuItems:[
		{id:"menuItem21_access",text:"Slow Identity Verification Process",target:'4_1_4'},
		
	],},
	{id:"menu3B_accelerated_access",x:0.05,y:0.25,heading:"The Solution",menuItems:[
		{id:"menuItem20_access",text:"Accelerated Access",target:'4_1_6'},
		
	],},
	{id:"menu3C_accelerated_access",x:0.05,y:0.4,heading:"How It Works",menuItems:[
		{id:"menuItem0_access",text:"Registration and Selfie Data",target:'4_1_1'},
		{id:"menuItem3_access",text:"Authentication Against Stored Tokens",target:'4_1_7'},
		 {id:"menuItem4_access",text:"Guest Entry",target:'4_1_5'},
		// {id:"menuItem5_access",text:"Back Entry",target:'4_1_6'},
		
	],},
	{id:"menu4_private_network",x:0.65,y:0.80,heading:"", menuItems:[
		{id:"menuItem40_access",text:"Click Here to Zoom in on the Solution",target:'2_1_3_1'},
		
	],},
	{id:"menu5_neutral_network",x:0.61,y:0.72,heading:"", menuItems:[
		{id:"menuItem40_access",text:"Click Here to Zoom in on the Solution",target:'2_1_2_1'},
		
	],},
];
