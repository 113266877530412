import React, { useEffect,Link} from 'react';
import { useState, useRef } from 'react';
import ReactPlayer from 'react-player';
import * as createjs from 'createjs-module';

import { SetupScene } from './js/main';
import { VerizonToolbar } from './js/toolbar';
import {localDir,videos,getVidUrl,} from './js/resourceMap';

import './App.css';

import {vidDir, labels, menuLists,  scenes} from './js/data';

function App() {
	// usestate to set a variable to the scene id
	// pass that variable to the SetupScene component
	// SetupScene will then find the scene with that id and render it
	// the scene will have a video and labels
	// the labels will be clickable and will change the scene
	// the video will have a clickable hotspot that will change the scene
	// the menu will have clickable items that will change the scene
	// the scene will have a transition in and out video
	// the transition video will be played when the scene is changed

	const [sceneId, setSceneId]= useState('0001');
	const [path, setPath]= useState(['0001']);
	const [sceneData, setSceneData]= useState(null);
	const [mainVid, setMainVid] = useState( '');
	const [mainVidLoop, setMainVidLoop] = useState(false);
	const [mainVidMute, setMainVidMute] = useState(false);
	//const [mainVidP, setMainVidMute] = useState(false);
	const [transVid, setTransVid] = useState( '');
	const [transIsPlaying, setTransIsPlaying] = useState( false);
	const [vidIsPlaying, setVidIsPlaying] = useState( false);
	const [prevData, setPrevData] = useState( null);
const [audioEnabled, setAudioEnabled] = useState(false);
const [audioStatus, setAudioStatus] = useState(true);
const [prevScene, setPrevScene] = React.useState(null);
let transRef = useRef(null);
	let vidRef = useRef(null);
let transRefPending = false;
let prog = 0;
useEffect(() => {
	//this.createjs = {};
	//window.createjs = this.createjs;
	//this.createjs.PreloadJS = createjs.PreloadJS;
	//this.createjs.LoadQueue = createjs.LoadQueue;
//console.log(createjs)
window.preload = createjs.PreloadJS;

window.loadQueue = createjs.LoadQueue;
//preloadAssets();
sessionStorage.setItem("dir", '');
}, []);

const handlePreloadComplete = () => {
	console.log('Preload complete');
	  };



const getSpeed =()=>{
	if (navigator.connection) {
		console.log(`Effective network type: ${navigator.connection.effectiveType}`);
		console.log(`Downlink Speed: ${navigator.connection.downlink}Mb/s`);
		console.log(`Round Trip Time: ${navigator.connection.rtt}ms`);
	  } else {
		console.log('Navigator Connection API not supported');
	  }
}

const changeScene = (id) => {
	setPrevScene(sceneId);
	console.log(sessionStorage.dir);
	let newPath = [...path];
	newPath.push(id);
	setPath(newPath);
	console.log("path", newPath);
	getSpeed();
	console.log("changing scene to ", id);
	
	setSceneId(id);
}

const getPrevData = (id) => {
	console.log("prev", id);
	if(id){
		let p = scenes.find(s => s.id === id);
		console.log(p);
		return p;
	}else{
		return null;
	}
}

const chkBackStatus = (id) => {
console.log("checking back status", id);
//let prevData = getPrevData(id);
//console.log("prevData", prevData);
	if(id != null && id !== "0001" ){ //|| (prevData || prevData.transOut)
		return false;
	}else{
		return true;
	}
}

const handleBack = () => {
	console.log("back",path);
	let newPath = [...path];
	let idx = newPath.length-2;
	let prev_Scene = newPath[idx];	
	newPath.pop();
	setPath(newPath);
setPrevScene(sceneId)
	console.log("changing scene to ", prev_Scene);
	sessionStorage.setItem("dir", 'back');
	setSceneId(prev_Scene);
}


////////////////////////////////////



useEffect(() => {	
	console.log("sceneId", sceneId);
	let sData = scenes.find(s => s.id === sceneId);
	setSceneData(sData);
	console.log("sData", sData);

	
	//setPrevData(getPrevData( sessionStorage.prevScene));
	setPrevData(getPrevData( prevScene));
	console.log(prevScene, sceneId)
	console.log("prevData", getPrevData( prevScene),prevScene);
	let pData = getPrevData( prevScene);
	if(sData){
console.log("dir", sessionStorage.dir)
		if((pData && pData.transOut && sessionStorage.dir ==="back") || sData.transIn && sessionStorage.dir !=="back"){
		console.log("found trans video")
		if(sessionStorage.dir === 'back'){

			if(pData.transOut){
				console.log("going back - use the transout video")
				//update
				updateVideoSrc(pData.transOut, 'transVideo', true);
			}
		}else{
			console.log("going forward use transIn clip", sData.transIn)
		if(sData.transIn){
			//setTransVid(sData.transIn);
			console.log("transIn", sData.transIn);
			updateVideoSrc(sData.transIn, 'transVideo', true);
		}
			}
	}else{
		console.log("no trans just play scene video")
		if(sData.vidSrc){
			handlePendingTrans();
			//setVidSrc(sData.vidSrc);
			let loop = false;
			if(sData.loop === true){
				loop = true;
			}
			//if(transIsPlaying === false){
				showMenus();
				
			//	}
			updateVideoSrc(sData.vidSrc, 'mainVideo', true,loop);
			//setMainVid(getVidUrl(sData.vidSrc));
			//setTransVid(null);
			//setPrevData(null)
		}
	}
	
}
	

}, [sceneId]);

const updateVideoSrc = (vidId,layer, autoPlay,loop) => {
	console.log("updateVideoSrc", vidId, layer, autoPlay);
if(!loop){loop = false;}
	let wrapper = document.getElementById(layer);
	console.log(wrapper)
	// let vid = wrapper.getElementsByTagName('video')[0];
	//console.log(vid)
	
	if(layer === 'transVideo' && vidId !== ''){
		let vidURL =getVidUrl(vidId);
		setTransVid(vidURL);
		if(autoPlay === true){
			//transRef.current.player.player.play();
			//vid.play();
			setTransIsPlaying(true);
		}
	}else{
		setMainVidMute(false);
		let vidURL =getVidUrl(vidId);
		setMainVid(vidURL);
		if(autoPlay === true){
			//vid.play();
			setVidIsPlaying(true);
			if(transIsPlaying === false){
			showMenus();
			}
		}
		
		if(loop === true){
			setMainVidLoop(true);
		}else{
			setMainVidLoop(false);
		}
	
	}
	
}




const transEnded = () => {
	// console.log("transEnded", transRef.current);	
	// 	console.log("vidrefready", vidRef.current.player.isReady);	
	// console.log(vidRef.current)
	// if(vidRef.current.player.isReady === true){
	// 	vidRef.current.player.player.play() ;
	// 	setTimeout(()=>{
			handlePendingTrans();
	// 	}, 0);
		
	// }else{
	// 	transRefPending = true;
	// }
	showMenus()
}


const handleTransPlay = () => {
	console.log("transPlaying", transRef.current.player);
	
	transRef.current.wrapper.classList.add("quickFadeIn")
	transRef.current.wrapper.classList.remove("fadeOut")
	setTimeout(()=>{
		let loop = false;
		if(sceneData.loop === true){
			loop = true;
		}
		updateVideoSrc(sceneData.vidSrc, 'mainVideo', true,loop);
	}, 300);
}

const showMenus = () => {
	let menus = document.querySelectorAll(".menu-list");
if(menus){
menus.forEach((m) => {
if(m){
	m.classList.add('quickFadeIn');
}
});
}
}

const handlePendingTrans = () => {

transRef.current.wrapper.classList.add('fadeOut');
 sessionStorage.setItem("dir", '');
// sessionStorage.setItem("prevScene", sceneData.id);

console.log("pending transition", sessionStorage.dir,prevData, sceneData)

transRefPending = false;
//vidRef.current.player.player.play();
}

const handleTransReady = () => {
	console.log("transReady", transRef.current.player);

	//transRef.current.player.player.play() ;//  = true;
}
const handleVidReady = () => {

	
// 	console.log("vidReady", vidRef.current.player);
// 	sessionStorage.setItem("prevScene", sceneData.id);
// 	let video = document.querySelector("#transVideo video");
// 	if(transRefPending === true){
// 		console.log("vidready.. process pending")
// 		handlePendingTrans();
// 	}else{
// 		console.log(sessionStorage.dir, prevData, sceneData.id);
// if(!sceneData.transIn && (!prevData || !prevData.transOut || sessionStorage.dir !== 'back')){
// console.log("no transvideo to play")
	
// 	//vidRef.current.player.player.play() ;
	

// }else{
// console.log("show transplayer")
// // transRef.current.wrapper.classList.add("quickFadeIn")
// // transRef.current.wrapper.classList.remove("fadeOut")

// //vidRef.current.player.player.play() ;
// //vidRef.current.player.player.pause() ;
// //console.log("set transout", prevData.transOut);
// if(video && prevData && prevData.transOut && sessionStorage.dir === 'back'){
// console.log("going back - use the transout video")
// //video.setAttribute("src", getVidUrl(prevData.transOut));
// //video.currentTime = 0;
// console.log("transRef - going back", transRef.current);
// updateVideoSrc(prevData.transOut, 'transVideo', true);
	
// }else{
// 	if(video){
// 	console.log("going forward use transIn clip", sceneData.transIn)
// 	//video.setAttribute("src", getVidUrl(sceneData.transIn));
// 	//video.currentTime = 0;
// 	updateVideoSrc(sceneData.transIn, 'transVideo', true);
	
// 	}
// }

// }
//  }
}

const testEnd = (e) => {
	//console.log("testEnd", e);
}

const testDuration = (e) => {
	//console.log("testDuration", e);
}
const testprog = (e) => {
	//console.log("duration",vidRef.current.duration);
	//console.log("testprog", e);
	//console.log(getAudioStatus())
if(e.played < prog){
setMainVidMute(true);
}else{
	//setMainVidMute(false);
	prog=e.played;
}
	
}
	
const getPrefetchList = () => {
	//let doc = document.querySelector("body");
	
	let prefetchList = [];
	videos.forEach((v) => {
		console.log(v)
		let urlref = getVidUrl(v.id);
		console.log(v.id, urlref)
		let prefetch = <link rel="prefetch" href={urlref} as="video" type="video/mp4" />;
		prefetchList.push(prefetch);
	// 	var link = document.createElement('link');
    // link.src = url;
    // link.rel = 'prefetch';
    // link.type = 'video/mp4'; // no need for HTML5
    // document.getElementsByTagName('head')[0].appendChild(link);
	}
	);
	return prefetchList;
}


const toggleMasterAudio = () => {
	console.log("toggleAudio");
	setAudioEnabled(!audioEnabled);
}

useEffect(() => {
	console.log("audioEnabled", audioEnabled);
	setAudioStatus(getAudioStatus());
}, [audioEnabled, mainVidMute]);

const getAudioStatus = () => {
	//console.log(mainVidMute, audioEnabled)
if(audioEnabled === true && mainVidMute === false){
	return false;
}else{
	return true}
}


const videoConfig = {
    file: {
      tracks: [
        {
          kind: "subtitles",
          src: "./assets/subtitles/test.srt",
          srcLang: "en",
          label: "English",
          default: true,
		  mode: "showing",
        },
      ],
    },
  };

  return (
    <div className="App">
		
		 <div className="content-wrapper">
	<ReactPlayer id="transVideo" preload="auto" ref={transRef}  url={transVid}  onReady={handleTransReady} onPlay={handleTransPlay} playing={transIsPlaying} onEnded={transEnded} controls={false}   loop={false}  width="100%" height="100%" />
	
	<ReactPlayer id="mainVideo" preload="auto" ref={vidRef} url={mainVid} progressInterval={100} onDuration={testDuration} volume={null} muted={audioStatus} onEnded={testEnd} onProgress={testprog}  onReady={handleVidReady} controls={false}  playing={vidIsPlaying} loop={mainVidLoop}  width="100%" height="100%" />
	
	 <SetupScene sceneId={sceneId} onChangeScene={(id)=>{changeScene(id)}} prevId={prevScene}  />

		
	</div>
	  <VerizonToolbar  handleBack={handleBack} hideBackBtn={chkBackStatus(sceneId)} onAudioToggle={toggleMasterAudio}/>
	
	  <div className="test">
	 {getPrefetchList().map((p) => {
		 return p;
	 }
	 )}
	  </div>
    </div>
  );
}

export default App;
