import React from "react";
import { CreateScene } from "./createScene";
import { scenes,labels,menuLists , vidDir} from "./data";

export function SetupScene (props)  {
	console.log("props", props)
	const onChangeScene = props.onChangeScene;
;
	let sceneId = props.sceneId;
	let prevId = props.prevId;
	let scene;
	if (!sceneId) {
	 scene = scenes[0];
	}else{
		scene = scenes.find(s => s.id === sceneId);
	}
//setPrevScene(currScene);
//	setCurrScene(scene.id);
		
	return CreateScene(scene,onChangeScene,sessionStorage.prevScene);
};

