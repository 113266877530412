import {videoLocation, hiRes} from './settings.js';

export const localDir = "./assets/vid/";
export const remoteDir = "https://cdn.muse.ai/u/b5vX1zf/";

export const videos =[
		{id:"initialHome",urlKey:"f98d01142d178d58a4a1cf359eb30f176e4280c934525f5a15f176a5df4b04c7", localFile:"initialHome.mp4", hires:true},
		{id:"ConventionCenter_Building",urlKey:"b32a2a421e3798759d8d3d5a52d4c56348da600d3eab48d9eaa11f3b4650a401" ,hires:true},
		{id:"TO_CONVENTION",urlKey:"5b2672df016d199066fe9911139b8ae7c0f6dc7f7c40f193d67d0aeca9346d75"},
		{id:"FROM_CONVENTION",urlKey:"d7e7f64b08eb745479ce50c977983231b2bd491b94b867b7e9cba7f1b3da36c7"},
	{id:"multicarrier",urlKey:"63a264521402286f33f2f515c02583c65a44269ead0fe6ab5455cdcc50841d1b"},
	{id:"FROM_multicarrier",urlKey:"9dee30d346d373f1ef412d0c00065cd8e06a210d952d9c86ae1c58d7e407206f"},
	{id:"TO_multicarrier",urlKey:"8d1f2c44a2707e5a0184abf048198c5afbb0e861a93818d06abc4ae89364464e"},
		
	{id:"Spotty_Connectivity",urlKey:"ab153b54e683a245c2e0c1b2e0156e277d59560edef76e3e86c4204ca5a7fb98"},
	{id:"Verizon_Neutral_Host",urlKey:"afab2efa6af7a192e6de111152cc93979906bc2133c971e715c1e761a27a9130"},
	{id:"Verizon_Neutral_Host_Inf",urlKey:"25447e9412374daa28bceeef9e9afbce80f9053f433e8aea1f2ec23e2e81390c"},
	{id:"Private_Network",urlKey:"ddc9f6d76e751f29a537d2d8d889b1415e6e3db3516ede3c0b674cec31e3d331"},
	{id:"Private_Network_Inf",urlKey:"71a30c2809a047e763ffecb50dc96c3b88ca752f6b32002d2d9cc965683964f9"},
	{id:"Radio_Solution",urlKey:""},
	{id:"Cashierless_Checkout_Solution",urlKey:"de8b4a82aa0672ae32c6bb496aa6a88a40368a4e482b14552660de361432acd2"},
	{id:"cashierless",urlKey:"e167b09d85952712a4c56e002ad0b123f4234731ef2233bc34e25aea1b13eb16"},
	{id:"TO_cashierless",urlKey:"5b59bf29a8a7cc5dee36a652abed1fa7848a387f4734c00edb66059e28fec92e"},
	{id:"FROM_cashierless",urlKey:"70ce68b625bcf5e4b2b6921c8d67bc1270d39b06787bd59c213f66b05e2930ff"},
	{id:"item_tracking",urlKey:"ddb30e8f513e245374df10b3356cbaa6b01dbd8833e1037b03f942aeb8a6fcef"},
	{id:"FROM_item_tracking",urlKey:"b90cdd4fe42c16b726ac4702a152f3533d7c254e7c5882687da6a72ee13dae63"},
	{id:"TO_item_tracking",urlKey:"5a231dd4a717bb5a475db8157ad7daa4a66adfb78f61e389fbb9b5dc01660ff9"},

	{id:"cashierless_security",urlKey:"eec70fa4b7644d81d56288a6072d47fe84fa26f27a776595202a88513973ba31"},
	{id:"TO_cashierless_security",urlKey:"436b15793de5cf7143be0365e8d60b4550c45224bd91b1bddde9ab91d7231e50"},
	{id:"FROM_cashierless_security",urlKey:"79942cddac00f997cb0ece299527f04e85fcdeec57bab24093ca9a5ee95064a7"},
	{id:"cashierless_out",urlKey:"35cde6db4b0a433d2947106ca19cd2bccdbccd2cc6177162e55655622ee43ea0"},
	{id:"FROM_cashierless_out",urlKey:"7e362632e235c81f404d5ed9d3f77e46e3f2984c9748aa24e780d2866d66fa45"},
	{id:"TO_cashierless_out",urlKey:"89581468c21bddfacffa3813720c9e7988ebc14767141716f28f1586f23d8d0f"},
	{id:"accelerated_access",urlKey:"0aaa557a7b9ee7b10fa6b0e1638143643862752cad5629bc841189267dcaa9ab"},
	{id:"TO_accelerated_access",urlKey:"a2a69cd8c705b2888898d521a9e07d132e27763552b75314161e916de8549978"},
	{id:"FROM_accelerated_access",urlKey:"cfce3ae3f104e34f8d49dc9b70e974e175fa5d3d5985260cacd540323ec9513b"},
		
	{id:"long_q",urlKey:"d87679b06f15d1dde6ef62fc08ff77deeaf0a434aa99dd16a20b1cf6697df6b7"},
	{id:"TO_long_q",urlKey:"a0a841f12b74e46a113eb55a53be760e21d6b13b6a1a120fc20c68514ef04348"},
	{id:"FROM_long_q",urlKey:"34c93f526c9f088cee940de722e47ffaea07dae618c65315ddcba38067bfd751"},
	
	{id:"registration",urlKey:"1b25fe61a3abca1e131d715df37941af8d3c473fec036e63d764e229a699877b"},
	{id:"TO_registration",urlKey:"96a3e3dd82b14e5650e44e5e7606058512c97e0f8b8caee98bd8c2a8841e208e"},
	{id:"FROM_registration",urlKey:"28a6c4be9e2a5f954154afe09986416a23444cae46f7e351fa7c9995dff3196f"},
	{id:"reduced_security",urlKey:"5ea0d764f4b1f81d3ca7562a3ec2df9abe8d182a2fb5e57c6933cf0cc0a8503c"},
	{id:"TO_reduced_security",urlKey:"46c9aac277e2f8066327a75dd5df84a3ca4b108606f0655a0644eafd85049212"},
	{id:"FROM_reduced_security",urlKey:"4e2726763f21f172705a76f67cff280fa1a497207f416fc1850997dac2211073"},
	{id:"authentication",urlKey:"f493745acbc2c90553ca3c65d99b58c0c1876da573707d52c26ad2dc48819bbd"},
	{id:"TO_authentication",urlKey:"2062e394f674d774dc42334ebb29f76d5ea0a7f58beafa7f0e8b71796bef60d1"},
	{id:"FROM_authentication",urlKey:"381a2d5203099d58a26e51263514079dc53442bc86d50f66c70e8c4e57fe5923"},
	{id:"slow_identity",urlKey:"15e17f70c6786be06c18a31992879e0044bf061494a6f3361b1426561ecc3862"},
	{id:"TO_slow_identity",urlKey:"5be0c303d65a0a7007c03bd87891834ddb6c86db73c99a04cc918990fd44fe5b"},
	{id:"FROM_slow_identity",urlKey:"28cd68c35774200e7bfac90548d278114b3481056719f12aae06a0824d5cd077"},
	{id:"front_entry",urlKey:"dc954400f94a2aeb52f886c4c1151b460764f3b37664b80a6baee57d70ac7ec8"},
	{id:"TO_front_entry",urlKey:"05108c5d45c06cd295cc769ffe47ff80cbfa2c222f27776ad80e69e10e77b588"},
	{id:"FROM_front_entry",urlKey:"cc68f106b4a79de73090ebec566b606c64cdac3202e40724619ca599a54550df"},
	{id:"back_entry",urlKey:"0603e45e5519639702b1a20dcb0030fc526d1c5fca381ae41d48e60a57fbf6f8"},
	{id:"TO_back_entry",urlKey:"64c08d0b1f103a6ed863d124ac677cd80d5c46bbf56bf7e568e39953ce9e4069"},
	{id:"FROM_back_entry",urlKey:"c7939cf6461e58960854880a7f3188dbfd206e1fddb912b7ed6e9ba96c8b1272"},
	
	{id:"dropped_calls",urlKey:"6fa1210acdb04defcbec1a4ed298ce7f52ebb5521a99e9e4357e26a06540416d"},
	{id:"TO_dropped_calls",urlKey:"ecf4313452cbac91eff9e6e904e9bad5600f25471d1df3b6b51e74b9802ca1fc"},
	{id:"FROM_dropped_calls",urlKey:"812a271778f144125619b6aafb7a76e0adce6e97f2ba95e101c079951932ff95"},
	{id:"data_access",urlKey:"18abe8f512533ada6119a69e9ccbb98a6ea9cc9abb43b5a178311dedccbc47ff"},
	{id:"TO_data_access",urlKey:"b99791bda722ce5c4f4624263453cfc3f5284c72d8ba4cebbb877dbe6cdc2b6e"},
	{id:"FROM_data_access",urlKey:"be263b31b115ce7d6ac1c70451b0a3f349d05a8db967a3472474cb8bf1f479cf"},
	{id:"latency",urlKey:"72f1628b26c9cda2b21aafa27299a420d806149e4ed42fe1489a2572aeddeb61"},
	{id:"TO_latency",urlKey:"d6242b5cc09ca8822ddd9d16b7c852697865c564044420ef915c1066becb8e90"},
	{id:"FROM_latency",urlKey:"0e4b3f1a1c6885b35e77efc199eaf5873ad4ecef8c8d4ae2e15862e6abbdc6c5"},
	{id:"tokens",urlKey:"a5b0c113d30362a42e682e28287c605a1da8f9e153de32fec687a188d12f834a"},
	{id:"TO_tokens",urlKey:"f120a25a1648c530ab4be8c90f40e442941b92760fd1f49bdb48ecd6b6b01dc9"},
	{id:"FROM_tokens",urlKey:"0a5bbb29d408fb2d13a011878125cf642faf9dab661a2cbaea317cf53cf7924f"},

	{id:"access_main",urlKey:"9e69166049f43cb1660a949cdcaf35da8bfcba9127af2353a60d50961af8893a"},
	{id:"TO_access_main",urlKey:"a2a69cd8c705b2888898d521a9e07d132e27763552b75314161e916de8549978"},
	{id:"FROM_access_main",urlKey:"cfce3ae3f104e34f8d49dc9b70e974e175fa5d3d5985260cacd540323ec9513b"},
	
	//{id:"",urlKey:""},

];


export const getVidUrl = (vid) => {
	console.log("getVidUrl", vid);
	let v = videos.find(v => v.id === vid);
	if(vid){
		if(videoLocation === 'local'){
let fullUrl = localDir + v.localFile;
			return fullUrl;
		}else{
			let _hiRes = v.hires ? true : hiRes;
			let vRes = _hiRes ? '/videos/video-1080p' : '/videos/video-720p';
			let fullUrl = remoteDir + v.urlKey + vRes + '.mp4';
			console.log("fullUrl", fullUrl);
			return fullUrl;
		}
		
	}else{
		return '';
	}
}