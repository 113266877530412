import React, {useState} from 'react';

export const VerizonToolbar = (props) =>{

	let handleBack = props.handleBack;
	let hideBackBtn = props.hideBackBtn;
	let toggleMasterAudio = props.onAudioToggle;
	//console.log("audio",toggleMasterAudio)
	console.log("hideBackBtn", hideBackBtn);
const [audioEnabled, setAudioEnabled] = useState(false);	
const [networkEnabled, setNetworkEnabled] = useState(false);
const [mapEnabled, setMapEnabled] = useState(false);
const [tourEnabled, setTourEnabled] = useState(true);


const loadPrevScene = () => {
	console.log("loadPrevScene");
	handleBack();
}

const toggleAudio = () => {
	console.log("toggleAudio");
	setAudioEnabled(!audioEnabled);
	toggleMasterAudio();
}

const toggleNetwork = () => {
	console.log("toggleNetwork");
	setNetworkEnabled(!networkEnabled);
}

const toggleMap = () => {
	console.log("toggleMap");
	setMapEnabled(!mapEnabled);
}

const toggleTour = () => {
	console.log("toggleTour");
	setTourEnabled(!tourEnabled);
}

	return <div className="toolbar">
		<div className={hideBackBtn === false ? 'toolbar-item  back ':'hidden toolbar-item  back' }  onClick={loadPrevScene} >Back</div>
		<div className="sub-tools">
		<div className={networkEnabled === true ? 'toolbar-item network hidden': 'toolbar-item network disabled hidden'} onClick={toggleNetwork}></div>
		<div className={audioEnabled === true ? 'toolbar-item audio ': 'toolbar-item audio disabled '} onClick={toggleAudio}></div>
		<div className={mapEnabled === true ? 'toolbar-item map hidden': 'toolbar-item map disabled hidden'} onClick={toggleMap} title="Site Map"></div>
		<div className={tourEnabled === true ? 'toolbar-item tour hidden': 'toolbar-item tour disabled hidden'} onClick={toggleTour}>{tourEnabled === false ? 'Pause Guide': 'Guided Tour'}</div>
		</div>
	</div>
}