import React, { useEffect } from 'react';
import { useState, useRef } from 'react';
import ReactPlayer from 'react-player';
import { labels, menuLists,  scenes} from './data';
import {localDir,videos,getVidUrl,} from './resourceMap';

const getLabels = (sLabels,updateFn) => {
	console.log(labels);
	return sLabels.map((l) => {
		console.log(l)
		let lbl = labels.find(lb => lb.id === l);
		console.log(lbl)
		return <div className="label" key={lbl.id} style={{left: `${lbl.x *100}%`,top: `${lbl.y *100}%`}} onClick={()=>{updateFn(lbl.target)}}>{lbl.text}</div>;
	});
};

// const getVidUrl = (vid) => {
// 	if(vid){
// 		let v = videos.find(v => v.id === vid);
// 		return v.url;
// 	}else{
// 		return '';
// 	}
// }

const getMenus = (sMenus,updateFn) => {
	return 	sMenus.map((m) => {
		let menu = menuLists.find(ml => ml.id === m);
		return <div className={"menu-list m-list-" + menu.id  }key={menu.id} style={{left: `${menu.x *100}%`,top: `${menu.y *100}%`}}>
			{menu.heading && <div className="menu-heading">{menu.heading}</div>}
			{menu.menuItems.map((mi) => {
			return <div className="menu-item" key={mi.id} onClick={()=>{updateFn(mi.target)}}><div>{mi.text}</div></div>;
		})}</div>;
	});
	
};


export const CreateScene = (sData, updateFn,prev) => {
	//console.log("createscene", sData);
	//console.log(prev)
	//console.log("prev" ,sessionStorage.prevScene);
	const [transVid, setTransVid] = useState( '');
	//const [prevData, setPrevData] = useState( null);
	const [mainVid, setMainVid] = useState( '');
	const [showTransVid, setShowTransVid] = useState(false);
	const [xPos, setXPos] = useState(0);
	const [yPos, setYPos] = useState(0);
	let transRef = useRef(null);
	let vidRef = useRef(null);


	const getMenuPos = (sMenus) => {
		console.log("getMenuPos", sMenus);
		if(sMenus && sMenus.length > 0){
		
			let menu = menuLists.find(ml => ml.id === sMenus[0]);// sMenus[0];
			console.log(menu.x, menu.y);
			let menuWrapper = document.querySelector(".menu-wrapper");
			if(menuWrapper){
				menuWrapper.style.left = `${menu.x *100}%`;
				menuWrapper.style.top = `${menu.y *100}%`;
			}
			// setXPos(menu.x*100);
			// setYPos(menu.y*100);
			// return {x: menu.x*100, y: menu.y*100};
		
	}
	}

	// const getPrevData = (id) => {
	// 	console.log("prev", prev);
	// 	if(prev){
	// 		let p = scenes.find(s => s.id === prev);
	// 		console.log(p);
	// 		return p;
	// 	}else{
	// 		return null;
	// 	}
	// }

// 	let prevData = getPrevData( sessionStorage.prevScene);

// 	console.log("prevData", prevData);
// let transClip = '';
// let transRefPending = false;
// console.log(sData)
// 	if(sData.transIn && transVid ===''){
// 		transClip = sData.transIn;
// 		setTransVid(sData.transIn);
// 		console.log("transvis", transRef.current);
// 	//transRef.current.player.url = getVidUrl(sData.transIn);
// }else{
// 	if(prevData && prevData.transOut){

// 		transClip = prevData.transOut;
// 		//setTransVid(prevData.transOut);
// 		//transRef.current.player.player.src = getVidUrl(prevData.transOut);
// 	//	transRef.current.player.player.play();
// 	}
// }
// if(transClip){
// 	console.log("***transclip", transClip);
// //setTransVid( transClip);
// }
// 	const transEnded = () => {
// 		console.log("transEnded", transRef.current);	
// 		//vidRef.current.wrapper.classList.remove('quickFadeOut');
// 		console.log("vidrefready", vidRef.current.player.isReady);	
// 		console.log(vidRef.current)
// 		if(vidRef.current.player.isReady === true){
// 			vidRef.current.player.player.play() ;
// 			vidRef.current.player.player.pause() ;
// 			setTimeout(()=>{
// 				handlePendingTrans();
// 			}, 500);
			
// 		}else{
// 			transRefPending = true;
// 		}
		
// //sessionStorage.setItem("prevScene", '');
// 	}

// const handlePendingTrans = () => {

// 	transRef.current.wrapper.classList.add('fadeOut');
// 	sessionStorage.setItem("dir", '');
// 	sessionStorage.setItem("prevScene", sData.id);
// 	let menus = document.querySelectorAll(".menu-list");
// 	if(menus){
// 	menus.forEach((m) => {
// 	if(m){
// 		m.classList.add('quickFadeIn');
// 	}
// });
// }
// 	transRefPending = false;
// 	vidRef.current.player.player.play();
// }

// 	const handleTransReady = () => {
// 		console.log("transReady", transRef.current.player);
		
// 		// 	transRef.current.wrapper.classList.add("quickFadeIn")
// 		// transRef.current.wrapper.classList.remove("fadeOut")
	
// 		if(prevData.transOut){
// 			//transRef.current.playbackRate = -1;
// 			//transRef.current.player.player.src = getVidUrl(prevData.transOut);
// 		}
		
// 		transRef.current.player.player.play() ;//  = true;
// 	}
// 	const handleVidReady = () => {

// 		console.log("vidReady", vidRef.current.player);
// 		sessionStorage.setItem("prevScene", sData.id);
// 		let video = document.querySelector("#transVideo video");
// 		if(transRefPending === true){
// 			console.log("vidready.. process pending")
// 			handlePendingTrans();
// 		}else{
// if(!sData.transIn && (!prevData || !prevData.transOut || sessionStorage.dir !== 'back')){
// 		//vidRef.current.wrapper.classList.add("quickFadeIn")
// 		vidRef.current.player.player.play() ;
// }else{
// 	console.log("show transplayer")
// 	transRef.current.wrapper.classList.add("quickFadeIn")
// 	transRef.current.wrapper.classList.remove("fadeOut")

// 	vidRef.current.player.player.play() ;
// 	vidRef.current.player.player.pause() ;
// 	console.log("set transout", prevData.transOut);
// 	if(video && prevData.transOut && sessionStorage.dir === 'back'){
	
// 	video.setAttribute("src", getVidUrl(prevData.transOut));
// 	video.currentTime = 0;
// 	console.log("transRef", transRef.current);
// 	}else{
// 		if(video){
// 		console.log("transIn clip")
// 		video.setAttribute("src", getVidUrl(sData.transIn));
// 		video.currentTime = 0;
// 		}
// 	}
	
// }
// }
// }
	

console.log(sData);
getMenuPos(sData.menus);
	return <>
	
		<div className="overlays">
		{sData.labels && getLabels(sData.labels, updateFn)}
		<div className="menu-wrapper" >
		{sData.menus && getMenus(sData.menus, updateFn)}
		</div>
	</div>
	</>
	
};